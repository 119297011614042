@import "../../styles/utils/variables";
@import "../../styles/utils/responsive";

.SearchContainer {
  margin: 0 auto 0;
  width: 88rem;

  @include media("<=phone") {
    width: 32.9rem;
  }
}

.SearchBar {
  display: grid;
  place-items: center;
  position: relative;
  width: 100%;

  input {
    width: 100%;
    background: #f7f7f7;
    border: 0.2rem solid #d2d2d2;
    border-radius: 1.2rem;
    box-sizing: border-box;
    font-family: &inter-family;
    padding: 0 5rem;
    height: 5rem;
    font-size: 1.6rem;

    &::placeholder {
      font-weight: 400;
      font-size: 1.6rem;
    }

    &:focus {
      outline: none;
    }
  }

  img {
    position: absolute;
    width: 2rem;
    height: 2rem;

    &:nth-child(1) {
      left: 2.3rem;
      top: 1.4rem;
    }

    &:nth-child(3) {
      right: 2.3rem;
      top: 2rem;
      cursor: pointer;
    }
  }
}

.mobileFilter {
  display: none;
  @include media("<=phone") {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.6rem;
    padding: 2rem 0;
    font-weight: 400;
    color: #464646;
    .mobileFilterContainer {
      display: flex;
      justify-content: end;
      align-items: center;
      gap: 1rem;
    }
  }
}

.customDrawer {
  border-top-left-radius: 2rem;
  border-top-right-radius: 2rem;
  overflow: hidden;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}