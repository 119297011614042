@import "../../styles/utils/responsive";

.Footer {
  width: 95%;
  margin: 6rem auto 0;
  display: flex;
  justify-content: space-between;

  a:visited {
    color: black;
  }

  span {
    display: block;
    padding: 2.6rem 0;
    font-size: 1.4rem;
    font-weight: 500;
    color: #111111;

    @include media("<=phone") {
      color: #464646;
      font-size: 1.2rem;
    }
  }
}
