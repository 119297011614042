@import "../../styles/utils/responsive";
@import "../../styles/shared/links";

.Navbar {
  width: 95%;
  margin: 3.09rem auto 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media("<=phone") {
    width: 90%;
    margin: 1.6rem auto 0;
  }

  ul {
    display: flex;
    gap: 3.3rem;

    a {
      @extend %link--hidden;

      font-size: 1.6rem;
      font-weight: 500;
      color: #464646;
      padding-bottom: 0.5rem;
      z-index: 3;

      @include media("<=phone") {
        padding-bottom: 0.3rem;
      }

      &:hover {
        color: #683522;
      }
    }
  }

  .Logo {
    width: 8.4rem;
    height: 8.1rem;
    z-index: 3;

    @include media("<=phone") {
      width: 6.9rem;
      height: 6.7rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

.active {
  color: #683522;

  @include media("<=phone") {
    display: none !important;
  }

  .Navbar & {
    color: #683522;
  }
}

.survey {
  width: 100%;
  background-color: #683522;
  color: white;
  font-size: 1.2rem;
  text-align: center;
  padding: 20px 0;

  @include media("<=phone") {
    font-size: 1.2rem;
    padding: 10px 20px;
    line-height: 2rem;
  }

  p {
    animation: bounce 7s infinite ease-in-out;
  }
}

@keyframes bounce {
  0%, 10% {
    transform: translateY(0);
  }
  5% {
    transform: translateY(-3px);
  }
  100% {
    transform: translateY(0);
  }
}
