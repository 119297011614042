@import "../../styles/utils/responsive";

// SINGLE COVER
.singleCover {
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 2;
  background-color: rgba(20, 18, 16, 0.15);

  @include media("<=phone") {
    z-index: 3;
  }

  .close {
    position: absolute;
    top: 3rem;
    right: 3rem;
    cursor: pointer;
    width: 3.5rem;
    height: 3.5rem;

    @include media("<=phone") {
      top: 1.5rem;
      right: 1.5rem;
    }
  }
}
.reportLink{
  font-size:16px;
  cursor:pointer;
  // text-decoration:underline;
  color: #464646;
}
.reportWrapper{
  display: flex;
  align-items: center;
  justify-content: right;
}
.truncate{
  color: #464646;
  font-size: 1.6rem;
  font-weight: 400;
  cursor:pointer;
  font-style: italic;
  text-decoration:underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width:187px;
}
.coverWrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  background: #fcf3ed;
  box-shadow: 1px 32px 21px 9px rgba(70, 70, 70, 0.2);
  width: 92.5rem;
  max-height: 52.4rem;
  gap: 5rem;
  padding: 7.2rem 7rem;
  border-radius: 1.6rem;

  @include media("<=phone") {
    width: 90%;
    max-height: 96%;
    overflow-y: scroll;
    // height: fit-content;
    flex-direction: column;
    gap: 1.4rem;
    padding: 6.2rem 1.5rem 1.2rem 1.5rem;
  }

 


}


.coverFirstWrapper {
  display: flex;

  gap: 5rem;

  @include media("<=phone") {

    flex-direction: column;
    gap: 1.4rem;
  
  }
  figure {
    width: 32rem;
    height: 32rem;
    position: relative;

    @include media("<=phone") {
      margin-top: 2rem;
      width: 30rem;
      height: 30rem;
      align-self: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      // position: absolute;
      // top: 0;
      // bottom: 0;
    }
  }

  .textContainer {
    max-width: calc(95% - 34rem);
    padding-top: 1.5rem;

    @include media("<=phone") {
      max-width: 100%;
    }

    h2 {
      font-size: 3rem;
      font-weight: 600;
      color: #171717;
      padding-bottom: 3rem;
      line-height: 3.9rem;

      @include media("<=phone") {
        font-size: 2.2rem;
        padding-bottom: 2.5rem;
        line-height: 3rem;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      gap: 1.8rem;
    }

    li {
      display: flex;
      gap: 9rem;

      @include media("<=phone") {
        gap: 6rem;
      }

      p {
        font-size: 1.6rem;
        font-weight: 500;
        max-width: 4rem;
        text-transform: uppercase;

        @include media("<=phone") {
          font-size: 1.6rem;
        }
      }

      span {
        color: #464646;
        font-size: 1.6rem;
        font-weight: 400;

        @include media("<=phone") {
          font-size: 1.6rem;
        }

        ul {
          display: flex;
          flex-direction: row;
          gap: 0.5rem;

          li {
            display: inline;
          }

          li:not(:last-child)::after {
            content: ",";
            padding: 0;
            margin: 0;
            display: inline;
          }
        }
      }
    }
  }


}