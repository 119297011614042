@import "../../styles/utils/responsive";

.about {
  height: 100%;
  margin: 4.09rem 16.7rem 0;
  display: flex;
  justify-content: space-between;

  @include media("<=phone") {
    width: 90%;
    margin: 4rem auto 0;
    flex-direction: column;
  }

  section {
    margin-bottom: 60px;
  }

  h2 {
    font-size: 2.8rem;
    font-weight: 600;
    margin-bottom: 2.4rem;

    @include media("<=phone") {
      font-size: 2.4rem;
      margin-bottom: 1.6rem;
    }
  }

  h3 {
    font-size: 2rem;
    font-weight: 500;
    margin-bottom: 3.2rem;
  }

  ul, ul > li {
    list-style: disc inside;
  }

  p, h4, li {
    font-size: 1.6rem;
    font-weight: normal;
    line-height: 2.4rem;
    color: #000000;
  }

  p, h4 {
    margin: 2rem 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4, b {
    font-weight: 600;
  }

  i {
    font-style: italic;
    font-family: none;
    font-size: 1.1em;
  }

  a {
    text-decoration: underline;
    color: black;
  }
  
  .aboutWrapper {
    max-width: 57.1rem;

    @include media("<=phone") {
      margin-bottom: 4.8rem;
    }
  }

  aside {

    .contributorContainer {
      display: flex;
      align-items: center;
      gap: 1.9rem;

      &:not(:last-child) {
        margin-bottom: 2rem;
      }

      img {
        width: 5rem;
        height: 5rem;
        object-fit: cover;
        border-radius: 100%;
      }

      .textContainer {
        p {
          font-size: 1.6rem;
          font-weight: 500;
          color: #171717;
          margin-bottom: 0.5rem;
        }

        span {
          font-size: 1.4rem;
          font-weight: 400;
          color: #464646;
        }
      }
    }
  }
}
