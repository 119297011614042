@import "../../styles/utils/variables";
@import "../../styles/utils/responsive";


.LoaderWrapper{
  width: 31%;
  margin: 6.4rem auto 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @include media("<=phone") {
    margin: 4rem auto 0;
    width: 90%;
  }
}