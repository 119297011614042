.hamburger {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 18px;
    cursor: pointer;
   
  }
  
  .line {
    width: 100%;
    height: 2px;
    background-color: #171717;
    margin-bottom: 4px; border-radius: 20px;
    transition: transform 0.3s ease;
  }
  
 
  