@import "../../styles/utils/variables";
@import "../../styles/utils/responsive";

.FilterContainer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2.4rem;

  .headTag {
    font-size: 1.8rem;
    font-weight: 500;
    color: $text-color;
  }
}

.FilterForm {
  width: 100%;
  margin-top: 2rem;
}

.FormBody {
  display: flex;
  flex-direction: column;
  gap: 2rem;

  input {
    color: $text-color;
    width: 100%;
    height: 100%;

    &[type="number"] {
      padding-left: 1rem;
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      &::placeholder {
        font-weight: 400;
        font-size: 1.4rem;
        color: #808080;
      }

      &:focus {
        outline: none;
      }
    }
  }

  .reset {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-size: 1.4rem;
        color: $text-color;
        font-weight: 500;
    }
  }

  .filter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    padding: 1.6rem;
    border-radius: 1.2rem;
    background-color: $brown-color;

    p {
        font-size: 1.4rem;
        color: #ffffff;
    }
  }
}

.selectContainer {
  border-radius: 1.2rem;
  font-size: 1.4rem;
  color: $text-color;
}