*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
  font-size: calc(100vw / 1440 * 10);
  overflow-x: hidden;

  @include media("<=phone") {
    font-size: calc(100vw / 390 * 10);
  }
}

body {
  font-family: $inter-family;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
}
