@import "../../styles/utils/variables";
@import "../../styles/utils/responsive";

.CoverBank {
  margin: 4.09rem 0 3.5rem;
  min-height: calc(100vh - 300px);
}

.CoverBankHeader {
  display: grid;
  place-items: center;
  text-align:center;

  p {
    font-weight: 400;
    font-size: 1.6rem;
    margin-bottom: 1.8rem;
    color: $text-color;
  }

  h2 {
    font-weight: 600;
    font-size: 4.8rem;
    line-height: 1.3;
    color: $brown-color;
    letter-spacing: -0.07em;
    margin-bottom: 4rem;
    padding:0 60px;

    @include media("<=phone") {
      font-size: 2.8rem;
    }
  }
}

.coverGrid {
  width: 80%;
  margin: 6.4rem auto 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  row-gap: 2.1rem;
  column-gap: 2rem;

  @include media("<=phone") {
    margin: 4rem auto 0;
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
    width: 90%;
  }


  figure {
    height: 20.4rem;
    width: 100%;
    position: relative;
    cursor: pointer;

    @include media("<=phone") {
      height: 15.4rem;
    }
  }

  img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    left: 0;
    top: 0;
  }

  p {
    margin: 1.8rem 0 0;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    color: #171717;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include media("<=phone") {
      font-size: 1.4rem;
  
    }
  }

  h4 {
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $text-color;

    @include media("<=phone") {
      font-size: 1.2rem;
    }
  }
}

.LogoWrapper{
  width: 80%;
    margin: 6.4rem auto 0;
    display: flex;
    align-items: center;
    justify-content: center;
    @include media("<=phone") {
      margin: 4rem auto 0;
      width: 90%;
    }
}

.errorMessage {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 560px);
  width: 100%;
  text-align: center;
}

.errorContent {
  max-width: 500px;
  padding: 2rem;
  
  p {
    font-size: 1.5rem;
    color: #666;
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }

  a {
    color: $brown-color;
    text-decoration: underline;
    
    &:hover {
      text-decoration: none;
    }
  }
}


.loadingState {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 560px);
  width: 100%;
  
  // Reuse the existing FetchMoreLoader styles
  .LoaderWrapper {
    margin: 0;
  }
}