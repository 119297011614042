@import "../../styles/utils/variables";
@import "../../styles/utils/responsive";

/* FILTER DROPDOWN */
.FilterContainer {
  position: relative;
  width: 100%;
  margin-top: 8px;

  @include media("<=phone") {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
    margin-top: 0;
  }

  .mobileLogo {
    display: none;

    @include media("<=phone") {
      display: block;
      position: absolute;
      top: 1.8rem;
      left: 1.6rem;
      width: 6.9rem;
      height: 6.7rem;
      object-fit: cover;
      z-index: 3;
    }
  }
}

.FilterForm {
  margin: auto;
  padding: 3rem;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 4;
  box-sizing: border-box;
  background: #fdfdfd;
  box-shadow: -4px 6px 12px rgba(0, 0, 0, 0.2);
  border-radius: 1.2rem;
  height: 30.9rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  padding-top: 11rem;

  @include media("<=phone") {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 11rem;
  }
}

.FormHeader {
  display: flex;
  padding-bottom: 3.1rem;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap:33px;

  img {
    width: 1.8rem;
    height: 1.8rem;
    object-fit: cover;
    cursor: pointer;

    @include media("<=phone") {
      position: absolute;
      top: 3.8rem;
      right: 1.5rem;
    }
  }
h2{
    
  font-weight: 600;
  font-size: 1.9rem;
  color: #171717;
  cursor: pointer;
}
  h4 {
    
    font-weight: 400;
    font-size: 2.9rem;
    color: #171717;
    cursor: pointer;
  }
}

.FormBody {
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 2.2rem;
  column-gap: 6.6rem;
  margin-bottom: 4.1rem;

  @include media("<=phone") {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  label {
    display: flex;
    align-items: center;
    color: $text-color;

    &:nth-child(1) {
      span {
        margin-right: 4.2rem;

        @include media("<=phone") {
          margin-right: 3.7rem;
        }
      }
    }
    &:nth-child(2) {
      span {
        margin-right: 4.2rem;

        @include media("<=phone") {
          margin-right: 2.8rem;
        }
      }
    }
    &:nth-child(3) {
      span {
        margin-right: 3.6rem;

        @include media("<=phone") {
          margin-right: 3.1rem;
        }
      }
    }
    &:nth-child(4) {
      span {
        margin-right: 2.3rem;

        @include media("<=phone") {
          margin-right: 0.9rem;
        }
      }
    }

    span {
      font-size: 1.4rem;
      font-weight: 400;
    }

    input {
      color: $text-color;
      width: 100%;
      height: 100%;
     

      &[type="number"] {
        padding-left: 1.4rem;
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
        }

        &::placeholder {
          font-weight: 400;
          font-size: 1.4rem;
          color: #4C4C4C;
        }

        &:focus {
          outline: none;
        }
      }
    }
  }

  .selectContainer,
  .inputContainer {
    height: 4.2rem;
    width: 26rem;
    border-radius: 1.2rem;
    font-size: 1.4rem;
    color: $text-color;
  }

  .inputContainer {
    border: 1.3px solid #d2d2d2;
  }
}

.FormButtons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 2rem;

  .resetBtn,
  .applyBtn {
    width: 9.2rem;
    height: 4rem;
    font-size: 1.4rem;
    font-weight: 400;
    cursor: pointer;
  }

  .resetBtn {
    border: 1px solid #d2d2d2;
    border-radius: 1.2rem;
    color: #706868;
    display: grid;
    place-items: center;
  }

  .applyBtn {
    background: #41312c;
    border: none;
    border-radius: 1.2rem;
    color: #fdfdfd;
  }
}
